<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>商家联盟</el-breadcrumb-item>
          <el-breadcrumb-item>门店审核列表</el-breadcrumb-item>
          <el-breadcrumb-item>审核门店详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <el-row :gutter="40">
        <el-col :span="24">
          <span class="grid-divider">商家信息</span>
          <el-divider></el-divider>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">商户类型</div>
          <el-input
            :placeholder="merchantType[query.merchantType]"
            :disabled="true"
          >
          </el-input>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">ID</div>
          <el-input
            placeholder=""
            v-model="infoDetail.merchantId"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">公司名称</div>
          <el-input
            placeholder=""
            v-model="infoDetail.companyName"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">手机号码（登录账号）</div>
          <el-input
            placeholder=""
            v-model="infoDetail.phoneNumber"
            :disabled="true"
          ></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="40">
        <el-col :span="6">
          <div class="sub-title">法人姓名</div>
          <el-input
            placeholder=""
            v-model="infoDetail.legaler"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">法人身份证号</div>
          <el-input
            placeholder=""
            v-model="infoDetail.legalerIdCardNo"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">统一社会信用代码</div>
          <el-input
            placeholder=""
            v-model="infoDetail.businessLicenseCode"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">执照有效期</div>
          <el-input
            placeholder=""
            v-model="infoDetail.effectiveDateEnd"
            :disabled="true"
          ></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="40">
        <el-col :span="6">
          <div class="sub-title">申请时间</div>
          <el-input
            placeholder=""
            v-model="infoDetail.applyDate"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="6"> </el-col>
        <el-col :span="6"> </el-col>
        <el-col :span="6"> </el-col>
      </el-row>
    </div>
    <div class="admin_main_block admin_m15">
      <el-row :gutter="40">
        <el-col :span="24">
          <span class="grid-divider">结算信息</span>
          <el-divider></el-divider>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">开户银行</div>
          <el-input
            placeholder=""
            v-model="infoDetail.bankName"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">银行卡号</div>
          <el-input
            placeholder=""
            v-model="infoDetail.bankNo"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">开户名称</div>
          <el-input
            placeholder=""
            v-model="infoDetail.bankUserName"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">帐户类型</div>
          <el-input
            placeholder=""
            v-model="infoDetail.openShopUserAccount"
            :disabled="true"
          ></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="40">
        <el-col :span="6">
          <div class="sub-title">结算类型</div>
          <el-input
            placeholder=""
            v-model="infoDetail.openShopUserAccount"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">开户省份</div>
          <el-input
            placeholder=""
            v-model="infoDetail.bankOpenArea"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">开户城市</div>
          <el-input
            placeholder=""
            v-model="infoDetail.openShopUserAccount"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">开户支行</div>
          <el-input
            placeholder=""
            v-model="infoDetail.bankAddress"
            :disabled="true"
          ></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="40">
        <el-col :span="6">
          <div class="sub-title">联行号</div>
          <el-input
            placeholder=""
            v-model="infoDetail.branchNo"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">预留手机号</div>
          <el-input
            placeholder=""
            v-model="infoDetail.phoneNumber"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="6"> </el-col>
        <el-col :span="6"> </el-col>
      </el-row>
    </div>
    <div class="admin_main_block admin_m15">
      <el-row :gutter="40">
        <el-col :span="24">
          <span class="grid-divider">门店信息</span>
          <el-divider></el-divider>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">门店ID</div>
          <el-input
            placeholder=""
            v-model="infoDetail.id"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">门店名称</div>
          <el-input
            placeholder=""
            v-model="infoDetail.shopName"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">经营类型</div>
          <el-input
            placeholder=""
            v-model="infoDetail.category"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">所属区域</div>
          <el-input
            placeholder=""
            v-model="infoDetail.shopArea"
            :disabled="true"
          ></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="40">
        <el-col :span="6">
          <div class="sub-title">赠豆比例</div>
          <el-input
            placeholder=""
            v-model="infoDetail.givingPercent"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">收款码ID</div>
          <el-input
            placeholder=""
            v-model="infoDetail.payCardNo"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">加入时间</div>
          <el-input
            placeholder=""
            v-model="infoDetail.applyDate"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="6"> </el-col>
      </el-row>
    </div>
    <div class="admin_main_block admin_m15">
      <el-row :gutter="40">
        <el-col :span="24">
          <span class="grid-divider">证件信息</span>
          <el-divider></el-divider>
        </el-col>
        <el-col :span="span" v-for="(rs,index) in infoDetail.merchantImages" :key="index">
          <div class="images">
            <div class="sub-title">
              {{rs.imageTypeText}}
              <!--              <el-button v-if="rs.imagePath" :data-img="[rs.imagePath]" type="text" size="small" @click="this.$imgPreview()">查看图片</el-button>-->
            </div>
            <upload
                    v-if="showPic"
                    @getUploadFile="getUploadFile"
                    title=""
                    :field="rs.imageType"
                    :param="upload.office"
                    :urlPath="rs.imagePath ? rs.imagePath : ''"
            ></upload>
          </div>
        </el-col>

      </el-row>
    </div>

    <div class="admin_main_block admin_m15">
      <el-row>
        <el-col>
          <el-button icon="el-icon-check" type="primary" @click="success">
            提报进件
          </el-button>
          <el-button icon="el-icon-back" type="primary" @click="back">
            驳回进件
          </el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import { baseUrl } from "@/plugins/api";
  import upload from "@/components/admin/upload.vue";
  export default {
    components: {
      upload,
    },
  data() {
    return {
      infoDetail: {
        legalerIdCardImageList: [],
        shopImageList: [],
        // shopHeadImage:[],
        businessLicenseImageList: [],
        businessCertificateList: [],
      },
      query: {},
      merchantType: {
        1: "个体户",
        2: "企业商户",
        3: "小微",
      },
      activities: [
        {
          content: "活动按期开始",
          timestamp: "2018-04-15",
        },
        {
          content: "通过审核",
          timestamp: "2018-04-13",
        },
        {
          content: "创建成功",
          timestamp: "2018-04-11",
        },
      ],
      showPic: true,
      lock: false,
      span:6,
      actionUploadUrl:'',
      upload: {
        title_card1: "身份证人像面",
        title_card2: "",
        title_card3: "",
        param: { fileType: "identity" },
        yyzz: { fileType: "business" },
        office: { fileType: "office" },
      },
    };
  },
  methods: {
    success() {
      this.$confirm("是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const postData = {
            state: 1,
            rejectReason: "",
            id: this.infoDetail.id,
          };
          this.incoming(postData);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    back() {
      this.$prompt("不通过理由", "商家门店审核", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(({ value }) => {
        const postData = {
          state: 2,
          rejectReason: value,
          id: this.infoDetail.id,
        };
        this.post(postData);
      });
    },
    infoData() {
      this.$get(
        this.$api.shopApplyInfo + "/" + this.$route.query.id,
        this.whale
      ).then((res) => {
        this.infoDetail = res.data;
      });
    },
    post(data) {
      this.$post(this.$api.shopApplyApprove, data).then((res) => {
        if (res.code === 1) {
            this.$message({
              type: "success",
              message: "成功!",
            });
          this.$router.go(-1);
        } else {
          this.$message({
            type: "error",
            message: "失败!",
          });
        }
      });
    },
    incoming() {
      this.$get(this.$api.incoming + "/" + this.infoDetail.id + "/0").then(
        (res) => {
          if (res.code === 1) {
            this.$message({
              type: "success",
              message: "成功!",
            });
            this.$router.go(-1);
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        }
      );
    },
    getUploadFile(val) {
      console.log(val.field);
      this.infoDetail.merchantImages.forEach((rs)=>{
        if(rs.imageType === val.field){
          var data ={
            "id": rs.id,
            "imageType": rs.imageType,
            "imagePath": val.url,
          };
          this.$post(this.$api.merchantImageUpdate, data).then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: "成功!",
              });
            } else {
              this.$message({
                type: "error",
                message: "失败!",
              });
            }
          });
        }
      });

    },
  },
  created() {
    this.query = this.$route.query;
    this.actionUploadUrl = baseUrl + "system/file/upload";
    this.infoData();
  },
};
</script>
<style lang="scss" scoped>
.el-row {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.el-tab-pane {
  padding-top: 10px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.el-divider--horizontal {
  margin: 12px 0px 12px 0px;
}

.sub-title {
  min-height: 26px;
}

.el-imag {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 260px;
  height: 178px;
}

.el-imag:hover {
  border-color: #409EFF;
}

.el-icon-picture-outline {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.layadmin-contact-box {
  width: 300px;
  display: inline-block;
  border: 1px solid #e7eaec;
  padding: 40px 0 40px 0;
}

.layadmin-text-center {
  text-align: center;
}

.layadmin-text-center .el-image {
  max-width: 40px;
  border-radius: 50%;
  margin-top: 5px;
  border: 1px dashed #d9d9d9;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 40px;
  height: 40px;
}

.el-card {
  text-align: center;
}

.header-form {
  height: 40px;
  padding: 10px 0 10px 0;
}

.sub-title {
  font-size: 14px;
  color: #606266;
}
</style>
